<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <card class="form-horizontal">

          <h3>Note</h3>

          <div class="row fatrow">
            <label class="col-sm-2 col-form-label">Client</label>
            <div class="col-sm-6">
              <el-button size="medium" @click.prevent="openClient">{{model.company_name}}</el-button>
            </div>
          </div>



          <div class="row fatrow">
            <label class="col-sm-2 col-form-label">Production</label>
            <div class="col-sm-4">
              <el-select class="select-default"
                          placeholder=""
                          name="production_id"
                          v-validate="modelValidations.production_id"
                          :error="getError('production_id')"
                          v-model="model.production_id">
                <el-option v-for="option in clientProductions"
                            class="select-primary"
                            :value="option.value"
                            :label="option.name"
                            :key="option.name">
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="row fatrow">
            <label class="col-sm-2 col-form-label">Sales Person</label>
            <div class="col-sm-4">
              <el-select class="select-default"
                          placeholder=""
                          v-model="model.user_id">
                <el-option v-for="option in salesTeam"
                            class="select-primary"
                            :value="option.value"
                            :label="option.name"
                            :key="option.name">
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="row fatrow">
            <label class="col-sm-2 col-form-label">Client Contact</label>
            <div class="col-sm-4">
              <el-select class="select-default"
                          placeholder=""
                          v-model="model.contact_id">
                <el-option v-for="option in salesContacts"
                            class="select-primary"
                            :value="option.value"
                            :label="option.name"
                            :key="option.name">
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="row fatrow">
            <label class="col-sm-2 col-form-label">Note Date</label>
            <div class="col-sm-2">
                <el-date-picker

                  name="ad_event_date"
                  v-validate="modelValidations.ad_event_date"
                  :error="getError('ad_event_date')"

                  v-model="model.ad_event_date"
                  type="datetime"
                  placeholder="Select date and time"
                >
                </el-date-picker>
            </div>
          </div>

          <div class="row fatrow">
            <label class="col-sm-2 col-form-label">Diary Date</label>
            <div class="col-sm-2">
                <el-date-picker
                  v-model="model.action_date"
                  type="datetime"
                  placeholder="Select date and time"
                >
                </el-date-picker>
            </div>
          </div>

          <div class="row fatrow">
            <label class="col-sm-2 col-form-label">Diary Type</label>
            <div class="col-sm-4">
              <el-select class="select-default"
                          placeholder=""
                          v-model="model.event_type">
                <el-option v-for="option in eventType"
                            class="select-primary"
                            :value="option.value"
                            :label="option.text"
                            :key="option.text">
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="row superfatrow">
            <label class="col-sm-2 col-form-label">Notes</label>
            <div class="col-sm-10">
              <textarea v-model="model.ad_event_notes" class="form-control" placeholder="" rows="10"></textarea>
            </div>
          </div>

          <div class="row fatrow">
            <!-- <label class="col-md-3"></label> -->
            <div class="col-md-9">
              <p-button type="info" @click.prevent="validate">
                Save
              </p-button>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
  import * as CONSTS from '../../../../../consts/constants'
  import { Tabs, TabPane, Card } from 'src/components/UIComponents'
  import swal from 'sweetalert2'
  import {DatePicker, TimeSelect, Slider, Tag, Input, Button, Select, Option } from 'element-ui'

  // create a dictionary for localised labels in forms
  const dictionary = {
    en: {
      attributes: {
        user_id: 'user',
        production_id: 'production',
        ad_event_date: 'date',
        action_date: 'action date',
        contact_id: 'contact',
        event_type: 'diary reminder',
        ad_event_notes: 'notes'
      }
    }
  }


  export default {
    computed: {

      isDisabled() {
        // evaluate whatever you need to determine disabled here...
        return this.mode == CONSTS.UPDATE ? true : false
        // return ;
      }
    },
    data() {
      return {
        mode: CONSTS.UPDATE,
        id: '',
        salesTeam: [],
        salesContacts: [],
        clientProductions: [],
        eventType: CONSTS.strings.event_type,
        model: {
          client_id: 0,
          user_id: 0,
          production_id: 0,
          ad_event_date: '',
          action_date: null,
          contact_id: 0,
          event_type: 0,
          ad_event_notes: ''

        },
        modelValidations: {


          production_id : {
            required: true,
            numeric: true,
            min_value: 1
          },
          ad_event_notes: {
            required: true
          },
          ad_event_date : {
            required: true
          }

        }
      }
    },
    created() {

      console.log(this.$route.params)

      if ( this.$route.params.clientId ){
        console.log('new note')
        this.mode = CONSTS.NEW
        this.model.client_id = this.$route.params.clientId
      } else if (this.$route.params.id){
        this.id = this.$route.params.id
        this.mode = CONSTS.UPDATE
      }

      // set up a dictionary for the EN form labels
      this.$validator.localize(dictionary)
    },
    mounted () {

      // main record
      if ( this.mode == CONSTS.NEW){
        this.getSalesTeam()
        this.getSalesContacts()
        this.getClientProductions()

        this.model.ad_event_date = this.$moment().toString()
      }

      if ( this.mode == CONSTS.UPDATE){
        this.$http.get( 'sales/note/' + this.id )
          .then(response => {

            if (response.data.hasOwnProperty("apiresponse") && response.data.apiresponse.status !== 'success'){
              this.$notify({
              message: 'There was a problem loading the note. Please try again. [1]',
              type: 'warning'
            })
            } else {
              this.model = response.data


              this.getSalesTeam()
              this.getSalesContacts()
              this.getClientProductions()

            }
          })
          .catch(error => {
            // console.log(error)
            this.$notify({
              message: 'There was a problem loading the production. Please try again. [2]',
              type: 'warning'
            })
          })
      }
    },
    components: {
      Card,
      [DatePicker.name]: DatePicker,
      [TimeSelect.name]: TimeSelect,
      [Slider.name]: Slider,
      [Tag.name]: Tag,
      [Input.name]: Input,
      [Button.name]: Button,
      [Option.name]: Option,
      [Select.name]: Select
    },
    methods: {
      getError(fieldName) {
        return this.errors.first(fieldName)
      },

      getSalesContacts() {

        let vm = this
          this.$http.get( 'sales/client/' + this.model.client_id + '/salescontactlist' )
          .then(response => ( this.salesContacts = response.data ))
          .catch(error => {
            // console.log(error)
            vm.$notify({
              message: 'There was a problem loading the sales contacts. Please try again.',
              type: 'warning'
            })
          })
      },

      getClientProductions(){

          let vm = this
          this.$http.get( 'common/client/' + this.model.client_id + '/production' )
          .then(response => ( vm.clientProductions = response.data ))
          .catch(error => {
            // console.log(error)
            vm.$notify({
              message: 'There was a problem loading the client productions. Please try again.',
              type: 'warning'
            })
          })
      },

      getSalesTeam() {

        let vm = this
          this.$http.get( 'sales/salesperson' )
          .then(response => ( this.salesTeam = response.data ))
          .catch(error => {
            // console.log(error)
            vm.$notify({
              message: 'There was a problem loading the sales team. Please try again.',
              type: 'warning'
            })
          })
      },

      updateRecord(){

          let vm = this

          // convert the JS ISO date to mysql datetime
          if (this.model.ad_event_date)this.model.ad_event_date = this.$moment(this.model.ad_event_date).format('YYYY-MM-DD HH:mm:ss')
          if (this.model.action_date) this.model.action_date = this.$moment(this.model.action_date).format('YYYY-MM-DD HH:mm:ss')


          this.$http.put( 'sales/note/' + this.id, this.model )
            .then(function (response) {

              if (response.data.apiresponse.status !== 'success'){

                vm.$notify({
                  message: 'There was a problem saving the note. Please try again. Error code: ' + response.data.apiresponse.code,
                  type: 'warning'
                })
              } else {

                vm.$notify({
                  message: 'Booking saved',
                  type: 'success'
                })
              }
              })
            .catch(function (error) {
              console.log(error)
              vm.$notify({
                message: 'There was a problem saving the note. Please try again.',
                type: 'warning'
              })
            })
      },

      insertRecord(){
          let vm = this

          // convert the JS ISO date to mysql datetime
          if (this.model.ad_event_date)this.model.ad_event_date = this.$moment(this.model.ad_event_date).format('YYYY-MM-DD HH:mm:ss')
          if (this.model.action_date) this.model.action_date = this.$moment(this.model.action_date).format('YYYY-MM-DD HH:mm:ss')

          this.$http.post( 'sales/note/', this.model )
            .then(function (response) {

              if (response.data.apiresponse.status !== 'success'){

                vm.$notify({
                  message: 'There was a problem saving the note. Please try again. Error code: ' + response.data.apiresponse.code,
                  type: 'warning'
                })
              } else {

                console.log(response.data.apiresponse.keys.guid)

                vm.id = response.data.apiresponse.keys.guid
                vm.mode = CONSTS.UPDATE

                vm.$notify({
                  message: 'Production saved',
                  type: 'success'
                })
              }
              })
            .catch(function (error) {
              console.log(error)
              vm.$notify({
                message: 'There was a problem saving the note. Please try again.',
                type: 'warning'
              })
            })
      },

      openClient(){
        this.$router.push({ name: 'Client', params: { id: this.model.client_guid }})
      },
      validate() {
        this.$validator.validateAll().then(isValid => {


          if (isValid){
            if(this.mode == CONSTS.UPDATE){
              this.updateRecord()
            } else {
              this.insertRecord()
            }
          } else {
            this.$notify({
              message: 'Please correct the validation issues and resubmit.',
              type: 'warning'
            })
          }
          //this.$emit('on-submit', this.registerForm, isValid)
        })
      }
    }
  }
</script>
<style scoped>

.el-select {
  display: block;
}

/*   div.row { height: 60px; } */

  div.fatrow { height: 60px; }

  div.superfatrow { height: 150px;}

  .divider div {
    height: 25px;
  }

  .right { text-align: right; }

</style>

